import DocViewer, { DocViewerRenderers, AvailableLanguages } from '@cyntler/react-doc-viewer';
import { useCallback, useState } from 'react';
import i18n from '../../../i18n';
import SvgRenderer from './SvgRenderer';
import { FileInfo } from '../../shared/file-upload/FileInfo';
import { FCWithChildren } from '../../../types/FCWithChildren';
import { FileUtils } from '../../../utils/FileUtils';
import { FileUploadType } from '../../shared/file-upload/FileuploadType';
import { useTranslation } from 'react-i18next';
import Loader from '../../shared/Loader';
import { ModalContext } from '../../../contexts/ModalContext';
import StandardModal from '../../shared/modal/variants/StandardModal';
import FileLink from '../../shared/FileLink';

type Props = {
  fileInfo: FileInfo;
};

// cannot preview ms docs using a blob url
const MS_DOC_MIME_TYPES = [
  'odt',
  'application/vnd.oasis.opendocument.text',
  'doc',
  'application/msword',
  'docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/octet-stream',
  'xls',
  'application/vnd.ms-excel',
  'xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'ppt',
  'application/vnd.ms-powerpoint',
  'pptx',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];
const SUPPORTED_MIME_TYPES = [...SvgRenderer.fileTypes, ...DocViewerRenderers.flatMap((x) => x.fileTypes)].filter(
  (x) => !MS_DOC_MIME_TYPES.includes(x),
);

const FilePreview: FCWithChildren<Props> = (props) => {
  const { fileInfo, children } = props;
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<FileInfo | null>(null);
  const { t } = useTranslation('common');
  const [loading, setLoading] = useState(false);

  const previewFile = useCallback(async () => {
    setLoading(true);
    setShowPreviewModal(true);
    const url = await FileUtils.getFileUrl(fileInfo.id);
    setSelectedFile({ ...fileInfo, url });
    setLoading(false);
  }, [fileInfo]);

  return (
    <>
      {fileInfo.type === FileUploadType.Link || !SUPPORTED_MIME_TYPES.includes(fileInfo.type) ? (
        <FileLink file={fileInfo}>{children}</FileLink>
      ) : (
        <button className="w-full cursor-pointer text-left" onClick={previewFile} key={fileInfo.id}>
          {children}
        </button>
      )}
      <ModalContext.Provider value={{ open: showPreviewModal, onClose: () => setShowPreviewModal(false) }}>
        <StandardModal
          title={selectedFile?.name || ''}
          confirmButtonTitle={t('file-viewer.buttons.download')}
          onConfirmClick={() => FileUtils.downloadFile({ id: fileInfo.id, name: selectedFile?.name ?? fileInfo.name, addExtension: true })}
          confirmDisabled={loading}
        >
          <div className="h-[72vh] min-h-64 w-full p-2">
            {!loading && selectedFile && selectedFile?.url && (
              <DocViewer
                documents={[{ uri: selectedFile.url, fileName: selectedFile.name, fileType: selectedFile.type }]}
                pluginRenderers={[SvgRenderer, ...DocViewerRenderers]}
                language={i18n.language as AvailableLanguages}
                config={{
                  header: {
                    disableHeader: true,
                  },
                  pdfVerticalScrollByDefault: false,
                }}
                theme={{
                  disableThemeScrollbar: true,
                  tertiary: 'transparent',
                }}
              />
            )}
            {loading && <Loader centered size={16} />}
          </div>
        </StandardModal>
      </ModalContext.Provider>
    </>
  );
};

export default FilePreview;
